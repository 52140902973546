import { FC, useState, useEffect } from 'react'
import DreamJobBGImgEN from '../../assets/img/hero-bg.png'
import DreamJobBGImgMobile from '../../assets/img/hero-bg-mobile.svg'
import { SearchOutlined } from '@ant-design/icons'
import FormOptimize from '../../../../components/Form'
import { LP_CANDIDATE_FORM_SEARCH_JOB } from '../../../../utillities/ConstantAutomation'
import { useTranslation } from '../../../../hooks/useTranslation'
import { ENUM_SEARCH_KEY } from '../../enum/ENUM_HOME_PAGE'
import { getIsServer } from '../../../../utillities/getIsServer'
import Row from '../../../../components/base/Grid/Row'
import Col from '../../../../components/base/Grid/Col'
import { prePathSearch } from '../../../../utillities/SEOUtilities'

const SearchDreamJob: FC<{ onFinish }> = (onFinish) => {
  const [bgSource, setBgSource] = useState(DreamJobBGImgEN.src)
  const { translate, currentLanguage } = useTranslation()
  const isMobile = getIsServer() || window.innerWidth <= 576

  useEffect(() => {
    isMobile ? setBgSource(DreamJobBGImgMobile.src) : setBgSource(DreamJobBGImgEN.src)
  }, [isMobile])

  return (
    <>
      <div
        className="dream-job"
        style={{
          backgroundImage: `url(${bgSource})`
        }}>
        <div className="wrapper-container">
          <Row>
            <Col xs={24} lg={12}>
              <div className="dream-job__content-col">
                {currentLanguage == 'en' ? (
                  <h1 className="dream-job__title">
                    Searching for <br />
                    <span className="main-color">your dream job </span>
                  </h1>
                ) : (
                  <h1 className="dream-job__title">
                    Tìm kiếm <span className="main-color">việc làm </span>
                    <br />
                    mơ ước
                  </h1>
                )}

                <div className="dream-job__attractive-postition">{translate('page_home:we_have_thousands_available')}</div>
                <FormOptimize
                  customStyle={{ pointerEvents: 'all' }}
                  noUseSubmit
                  id={LP_CANDIDATE_FORM_SEARCH_JOB}
                  className="search-form"
                  onSubmit={onFinish.onFinish}>
                  {({ register }) => {
                    return (
                      <>
                        <div className="d-flex search-wrapper">
                          <div className="d-flex search-input">
                            <div className="search-input-left">
                              <SearchOutlined style={{ fontSize: 24, color: '#6c7b8d', marginRight: 6 }} />
                            </div>
                            <input
                              className="dream-job__search-input"
                              placeholder={translate('page_home:search_list_company')}
                              {...register('title')}
                            />
                          </div>
                          <button type="submit" className="dream-job__btn-search d-none d-sm-block">
                            {translate('page_home:search_jobs')}
                          </button>
                        </div>

                        <button type="submit" className="dream-job__btn-search d-sm-none">
                          {translate('page_home:search_jobs')}
                        </button>
                      </>
                    )
                  }}
                </FormOptimize>
                {ENUM_SEARCH_KEY?.length > 0 && (
                  <div>
                    {ENUM_SEARCH_KEY.map((search_key, index) => (
                      <a
                        className="dream-job__search-keyword"
                        key={search_key.name}
                        href={`${prePathSearch(currentLanguage)}?q=${search_key.link}`}>
                        {search_key.name}
                        {index !== ENUM_SEARCH_KEY.length - 1 && ', '}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default SearchDreamJob
