export const PAGE_ADMIN_COMPANIES_LINK_ADD_AS_A_CLIENT = 'PAGE_ADMIN_COMPANIES_LINK_ADD_AS_A_CLIENT'

// Common
export const SAVE_JOB_BUTTON = 'SAVE_JOB_BUTTON'
export const COPY_SHARE_LINK_BUTTON = 'COPY_SHARE_LINK_BUTTON'
export const SHARE_BUTTON = 'SHARE_BUTTON'
export const JOB_CARD_BUTTON_SIMILAR = 'JOB_CARD_BUTTON_SIMILAR'
export const JOB_CARD_BUTTON_VIEW_CLIENT_LOGO = 'JOB_CARD_BUTTON_VIEW_CLIENT_LOGO'
export const JOB_CARD_BUTTON_VIEW_CLIENT_NAME = 'JOB_CARD_BUTTON_VIEW_CLIENT_NAME'

// JOB ALERT
export const JOB_ALERT_BUTTON_ADD = 'JOB_ALERT_BUTTON_ADD'
export const JOB_ALERT_INPUT_ADDRESS = 'JOB_ALERT_INPUT_ADDRESS'
export const JOB_ALERT_BUTTON_RECEIVE_NOTIFICATION = 'JOB_ALERT_BUTTON_RECEIVE_NOTIFICATION'
export const JOB_ALERT_MODAL_INPUT_KEYWORD = 'JOB_ALERT_MODAL_INPUT_KEYWORD'
export const JOB_ALERT_MODAL_SELECT_LOCATION = 'JOB_ALERT_MODAL_SELECT_LOCATION'
export const JOB_ALERT_MODAL_INPUT_EMAIL = 'JOB_ALERT_MODAL_INPUT_EMAIL'
export const JOB_ALERT_MODAL_BUTTON_CANCEL = 'JOB_ALERT_MODAL_BUTTON_CANCEL'
export const JOB_ALERT_MODAL_BUTTON_OK = 'JOB_ALERT_MODAL_BUTTON_OK'

// SEARCH
export const SEARCH_JOB_INPUT_SEARCH_JOB = 'SEARCH_JOB_INPUT_SEARCH_JOB'
export const SEARCH_JOB_BUTTON_CLEAR_SEARCH_JOB = 'SEARCH_JOB_BUTTON_CLEAR_SEARCH_JOB'
export const SEARCH_JOB_SELECT_LOCATION = 'SEARCH_JOB_SELECT_LOCATION'
export const SEARCH_JOB_SELECT_CAREER = 'SEARCH_JOB_SELECT_CAREER'
export const SEARCH_JOB_BUTTON_FILTER = 'SEARCH_JOB_BUTTON_FILTER'
export const SEARCH_JOB_BUTTON_SUBMIT = 'SEARCH_JOB_BUTTON_SUBMIT'
export const SEARCH_JOB_SELECT_DATE_POSTED = 'SEARCH_JOB_SELECT_DATE_POSTED'
export const SEARCH_JOB_SELECT_SALARY = 'SEARCH_JOB_SELECT_SALARY'
export const SEARCH_JOB_SELECT_EMPLOYMENT_TYPES = 'SEARCH_JOB_SELECT_EMPLOYMENT_TYPES'
export const SEARCH_JOB_SELECT_JOB_LEVEL = 'SEARCH_JOB_SELECT_JOB_LEVEL'
export const SEARCH_JOB_RECENT_SEARCH = 'SEARCH_JOB_RECENT_SEARCH'
// APPLY WITH CV
export const APPLY_WITH_CV_INPUT_FULL_NAME = 'APPLY_WITH_CV_INPUT_FULL_NAME'
export const APPLY_WITH_CV_INPUT_EMAIL = 'APPLY_WITH_CV_INPUT_EMAIL'
export const APPLY_WITH_CV_INPUT_PHONE = 'APPLY_WITH_CV_INPUT_PHONE'
export const APPLY_WITH_CV_TEXTAREA_DESCRIPTION = 'APPLY_WITH_CV_TEXTAREA_DESCRIPTION'
export const APPLY_WITH_CV_BUTTON_APPLY = 'APPLY_WITH_CV_BUTTON_APPLY'
export const APPLY_WITH_CV_BUTTON_CANCEL = 'APPLY_WITH_CV_BUTTON_CANCEL'

// REGISTER APPLY
export const REGISTER_APPLY_INPUT_FULL_NAME = 'REGISTER_APPLY_INPUT_FULL_NAME'
export const REGISTER_APPLY_INPUT_EMAIL = 'REGISTER_APPLY_INPUT_EMAIL'
export const REGISTER_APPLY_INPUT_PASSWORD = 'login_form_password'
export const REGISTER_APPLY_LINK_TERMS = 'REGISTER_APPLY_LINK_TERMS'
export const REGISTER_APPLY_LINK_POLICY = 'REGISTER_APPLY_LINK_POLICY'
export const REGISTER_APPLY_BUTTON_REGISTER = 'REGISTER_APPLY_BUTTON_REGISTER'
export const REGISTER_APPLY_BUTTON_LOGIN = 'REGISTER_APPLY_BUTTON_LOGIN'

// LOGIN APPLY
export const LOGIN_APPLY_INPUT_EMAIL = 'LOGIN_APPLY_INPUT_EMAIL'
export const LOGIN_APPLY_INPUT_PASSWORD = 'LOGIN_APPLY_INPUT_PASSWORD'
export const LOGIN_APPLY_LINK_FORGOT_PASSWORD = 'login_form_password'
export const LOGIN_APPLY_LINK_TERMS = 'LOGIN_APPLY_LINK_TERMS'
export const LOGIN_APPLY_LINK_POLICY = 'LOGIN_APPLY_LINK_POLICY'
export const LOGIN_APPLY_BUTTON_LOGIN = 'LOGIN_APPLY_BUTTON_LOGIN'
export const LOGIN_APPLY_BUTTON_REGISTER = 'LOGIN_APPLY_BUTTON_REGISTER'

// OPTION APPLY
export const OPTION_APPLY_BUTTON_EDIT = 'OPTION_APPLY_BUTTON_EDIT'
export const OPTION_APPLY_BUTTON_CHANGE_OR_UPLOAD_CV = 'OPTION_APPLY_BUTTON_CHANGE_OR_UPLOAD_CV'
export const OPTION_APPLY_BUTTON_CONTINUE = 'OPTION_APPLY_BUTTON_CONTINUE'

// APPLY
export const APPLY_BUTTON_UPLOAD_MY_CV = 'APPLY_BUTTON_UPLOAD_MY_CV'

// PAGE JOB DETAIL
export const PAGE_JOBS_DETAIL_LINK_VIEW_CLIENT_LOGO = 'PAGE_JOBS_DETAIL_LINK_VIEW_CLIENT_LOGO'
export const PAGE_JOBS_DETAIL_LINK_VIEW_CLIENT_NAME = 'PAGE_JOBS_DETAIL_LINK_VIEW_CLIENT_NAME'
export const PAGE_JOBS_DETAIL_STICKY = 'PAGE_JOBS_DETAIL_STICKY'
export const PAGE_JOBS_DETAIL_BUTTON_TRACKING_ID_APPLY = 'PAGE_JOBS_DETAIL_BUTTON_TRACKING_ID_APPLY'
export const PAGE_JOBS_DETAIL_BUTTON_JOB_CLOSED = 'PAGE_JOBS_DETAIL_BUTTON_JOB_CLOSED'
export const PAGE_JOBS_DETAIL_BUTTON_JOB_REJECTED = 'PAGE_JOBS_DETAIL_BUTTON_JOB_REJECTED'
export const PAGE_JOBS_DETAIL_BUTTON_JOB_UNDER_REVIEW = 'PAGE_JOBS_DETAIL_BUTTON_JOB_UNDER_REVIEW'

// LP CANDIDATE
export const LP_CANDIDATE_FORM_SEARCH_JOB = 'LP_CANDIDATE_FORM_SEARCH_JOB'
export const LP_CANDIDATE_BUTTON_SEARCH_JOB = 'LP_CANDIDATE_BUTTON_SEARCH_JOB'
export const LP_CANDIDATE_BUTTON_CREATE_CV = 'LP_CANDIDATE_BUTTON_CREATE_CV'
export const LP_CANDIDATE_BUTTON_GET_STARTED = 'LP_CANDIDATE_BUTTON_GET_STARTED'
