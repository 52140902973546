export const ENUM_SEARCH_KEY = [
  {
    name: 'Marketing',
    link: 'marketing'
  },
  {
    name: 'sales',
    link: 'sales'
  },
  {
    name: 'Javascript',
    link: 'javascript'
  },
  {
    name: 'Java',
    link: 'java'
  },
  {
    name: 'Android',
    link: 'android'
  },
  {
    name: 'IOS',
    link: 'ios'
  },
  {
    name: 'Python',
    link: 'python'
  },
  {
    name: 'Mobile',
    link: 'mobile'
  }
]
